.booking-page-container{
  position: relative;
  padding: 20px;
}


.booking-page {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #eee;
    border-radius: 8px;
  }

  
  .booking-form {
    display: flex;
    flex-direction: column;
  }
  
  .booking-form label {
    margin-bottom: 10px;
    color: #333333;
  }
  
  .booking-form input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333333;
  }
  
  .booking-form button {
    margin-top: 50px;
    padding: 10px;
    background-color: #333333;
    color: #ffffff;
    font-size: large;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .booking-form button:hover {
    background-color: #555555;
  }
  
  .error {
    color: #ff4d4f;
    margin-bottom: 10px;
  }
  

  
  .booking-confirmation h2  {
    color: #333333;
    text-align: center;
  }
  
  .booking-confirmation h4  {
    text-align: center;
    color: #333333;
  }
  
  .booking-confirmation p  {
    display: flex;
    align-items: center;
    justify-self: center;
    direction: rtl;
    color: #333333;
  }
  .booking-confirmation p span {
    color: #666666;

  }


  @media (max-width: 768px) {
    .booking-page {
      max-width: 500px;
      margin: 20px;
      padding: 20px;
      background-color: #eee;
      border-radius: 8px;
    }
  }